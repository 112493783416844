.about {
    padding: 4rem;
    background: #fff;
  }
  
h1 {
    text-align: center;
  }

  a, a:visited {
    color: black;
    text-decoration: none;
  }

.about__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }

  
  .about-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
  }

  
  .about-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .about-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }

  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
  }