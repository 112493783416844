.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    border-radius: 10px;
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }

  @media only screen and (max-width: 1600px) {
    .image {
      width: 400px;
      height: auto;
    }
  }

  @media only screen and (max-width: 1200px) {
    .image {
      width: 400px;
      height: auto;

    }
  }
  @media only screen and (max-width: 1000px) {
    .image {
      width: 400px;
      height: auto;

    }
  }

  @media only screen and (max-width: 900px) {
    .image {
      width: 400px;
      height: auto;

    }
  }

  @media only screen and (max-width: 800px) {
    .image {
      width: 400px;
      height: auto;

    }
  }
  @media only screen and (max-width: 700px) {
    .image {
      width: 400px;
      height: auto;;

    }
  }

  @media only screen and (max-width: 600px) {
    .image {
      width: 350px;
      height: auto;

    }
  }
  @media only screen and (max-width: 500px) {
    .image {
      width: 300px;
      height: auto;

    }
  }

  @media only screen and (max-width: 400px) {
    .image {
      width: 250px;
      height: auto;

    }
  }