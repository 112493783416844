video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .banner-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .banner-container > h1 {
    color: #fff;
    font-size: 100px;
  }
    
  .banner-container > h2 {
    color: #fff;
  }
  
  .banner-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif; */
  }
  
  /* .banner-btns {
    margin-top: 32px;
  }
  
  .banner-btns .btn {
    margin: 6px;
  } */
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    /* .banner-container {
      height:96vh;

    } */
    .banner-container > h1 {
      font-size: 70px;
      margin-top: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    /* .banner-container {
      height:91vh;
    } */
    .banner-container > h1 {
      font-size: 50px;
      margin-top: 30px;
    }
  
    .banner-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    .banner-container {
      height:87vh;
    }
  }